body {
  margin: 0;
  font-family: "Apple Gothic",AppleGothic,"URW Gothic L","Avant Garde",Futura,sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.05em;
}

.smoke-background
{
  position: relative;
  min-width: 100%;
  height: 30vh;
  overflow: hidden;
}

.smoke-background .background
{
  position: absolute;
  z-index: -1;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.smoke-background .logo
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 34%;
}

@media only screen and (max-width: 600px) {
  .smoke-background .logo
  {
    width: 60%;
  }

  .smoke-background .background
  {
    z-index: -1;
    width: auto;
    min-width: 100%;
    height: 100%;
  }
}

.app
{
  min-height: 100vh;
}

.support-form
{
  padding: 40px 40px;
}

.products
{
  padding: 40px 40px;
}

.load-center
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
